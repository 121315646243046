
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


//detalledeturno routes
			{
				path: '/detalledeturno/:fieldName?/:fieldValue?',
				name: 'detalledeturnolist',
				component: () => import('./pages/detalledeturno/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/detalledeturno/view/:id', 
				name: 'detalledeturnoview', 
				component: () => import('./pages/detalledeturno/view.vue'), 
				props: true
			},
		
			{ 
				path: '/detalledeturno/add', 
				name: 'detalledeturnoadd', 
				component: () => import('./pages/detalledeturno/add.vue'), 
				props: true
			},
	
			{ 
				path: '/detalledeturno/edit/:id', 
				name: 'detalledeturnoedit', 
				component: () => import('./pages/detalledeturno/edit.vue'), 
				props: true
			},
		

//diasemana routes
			{
				path: '/diasemana/:fieldName?/:fieldValue?',
				name: 'diasemanalist',
				component: () => import('./pages/diasemana/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/diasemana/view/:id', 
				name: 'diasemanaview', 
				component: () => import('./pages/diasemana/view.vue'), 
				props: true
			},
		
			{ 
				path: '/diasemana/add', 
				name: 'diasemanaadd', 
				component: () => import('./pages/diasemana/add.vue'), 
				props: true
			},
	
			{ 
				path: '/diasemana/edit/:id', 
				name: 'diasemanaedit', 
				component: () => import('./pages/diasemana/edit.vue'), 
				props: true
			},
		

//horariodeatencion routes
			{
				path: '/horariodeatencion/:fieldName?/:fieldValue?',
				name: 'horariodeatencionlist',
				component: () => import('./pages/horariodeatencion/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/horariodeatencion/view/:id', 
				name: 'horariodeatencionview', 
				component: () => import('./pages/horariodeatencion/view.vue'), 
				props: true
			},
		
			{ 
				path: '/horariodeatencion/add', 
				name: 'horariodeatencionadd', 
				component: () => import('./pages/horariodeatencion/add.vue'), 
				props: true
			},
	
			{ 
				path: '/horariodeatencion/edit/:id', 
				name: 'horariodeatencionedit', 
				component: () => import('./pages/horariodeatencion/edit.vue'), 
				props: true
			},
		

//horarios routes
			{
				path: '/horarios/:fieldName?/:fieldValue?',
				name: 'horarioslist',
				component: () => import('./pages/horarios/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/horarios/view/:id', 
				name: 'horariosview', 
				component: () => import('./pages/horarios/view.vue'), 
				props: true
			},
		
			{ 
				path: '/horarios/add', 
				name: 'horariosadd', 
				component: () => import('./pages/horarios/add.vue'), 
				props: true
			},
	
			{ 
				path: '/horarios/edit/:id', 
				name: 'horariosedit', 
				component: () => import('./pages/horarios/edit.vue'), 
				props: true
			},
		

//marcas routes
			{
				path: '/marcas/:fieldName?/:fieldValue?',
				name: 'marcaslist',
				component: () => import('./pages/marcas/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/marcas/view/:id', 
				name: 'marcasview', 
				component: () => import('./pages/marcas/view.vue'), 
				props: true
			},
		
			{ 
				path: '/marcas/add', 
				name: 'marcasadd', 
				component: () => import('./pages/marcas/add.vue'), 
				props: true
			},
	
			{ 
				path: '/marcas/edit/:id', 
				name: 'marcasedit', 
				component: () => import('./pages/marcas/edit.vue'), 
				props: true
			},
		

//permissions routes
			{
				path: '/permissions/:fieldName?/:fieldValue?',
				name: 'permissionslist',
				component: () => import('./pages/permissions/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/permissions/view/:id', 
				name: 'permissionsview', 
				component: () => import('./pages/permissions/view.vue'), 
				props: true
			},
		
			{ 
				path: '/permissions/add', 
				name: 'permissionsadd', 
				component: () => import('./pages/permissions/add.vue'), 
				props: true
			},
	
			{ 
				path: '/permissions/edit/:id', 
				name: 'permissionsedit', 
				component: () => import('./pages/permissions/edit.vue'), 
				props: true
			},
		

//roles routes
			{
				path: '/roles/:fieldName?/:fieldValue?',
				name: 'roleslist',
				component: () => import('./pages/roles/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/roles/view/:id', 
				name: 'rolesview', 
				component: () => import('./pages/roles/view.vue'), 
				props: true
			},
		
			{ 
				path: '/roles/add', 
				name: 'rolesadd', 
				component: () => import('./pages/roles/add.vue'), 
				props: true
			},
	
			{ 
				path: '/roles/edit/:id', 
				name: 'rolesedit', 
				component: () => import('./pages/roles/edit.vue'), 
				props: true
			},
		

//servicio routes
			{
				path: '/servicio/:fieldName?/:fieldValue?',
				name: 'serviciolist',
				component: () => import('./pages/servicio/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/servicio/view/:id', 
				name: 'servicioview', 
				component: () => import('./pages/servicio/view.vue'), 
				props: true
			},
		
			{ 
				path: '/servicio/add', 
				name: 'servicioadd', 
				component: () => import('./pages/servicio/add.vue'), 
				props: true
			},
	
			{ 
				path: '/servicio/edit/:id', 
				name: 'servicioedit', 
				component: () => import('./pages/servicio/edit.vue'), 
				props: true
			},
		

//turno routes
			{
				path: '/turno/:fieldName?/:fieldValue?',
				name: 'turnolist',
				component: () => import('./pages/turno/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/turno/view/:id', 
				name: 'turnoview', 
				component: () => import('./pages/turno/view.vue'), 
				props: true
			},
		
			{ 
				path: '/turno/add', 
				name: 'turnoadd', 
				component: () => import('./pages/turno/add.vue'), 
				props: true
			},
	
			{ 
				path: '/turno/edit/:id', 
				name: 'turnoedit', 
				component: () => import('./pages/turno/edit.vue'), 
				props: true
			},
		
			{
				path: '/turno/ultimos_turnos/:fieldName?/:fieldValue?',
				name: 'turnoultimos_turnos',
				component: () => import('./pages/turno/ultimos_turnos.vue'), 
				props: route => passRouteToProps(route)
			},
	

//usuarios routes
			{
				path: '/usuarios/:fieldName?/:fieldValue?',
				name: 'usuarioslist',
				component: () => import('./pages/usuarios/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/usuarios/view/:id', 
				name: 'usuariosview', 
				component: () => import('./pages/usuarios/view.vue'), 
				props: true
			},
		
			{ 
				path: '/index/register', 
				name: 'usuariosuserregister', 
				component: () => import('./pages/index/userregister.vue'), 
				props: true
			},
	
			{ 
				path: '/account/edit', 
				name: 'usuariosaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'usuariosaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/usuarios/add', 
				name: 'usuariosadd', 
				component: () => import('./pages/usuarios/add.vue'), 
				props: true
			},
	
			{ 
				path: '/usuarios/edit/:id', 
				name: 'usuariosedit', 
				component: () => import('./pages/usuarios/edit.vue'), 
				props: true
			},
		
			{ 
				path: '/usuarios/landing', 
				name: 'usuarioslanding', 
				component: () => import('./pages/usuarios/landing.vue'), 
				props: true
			},
	

//vehiculo routes
			{
				path: '/vehiculo/:fieldName?/:fieldValue?',
				name: 'vehiculolist',
				component: () => import('./pages/vehiculo/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/vehiculo/view/:id', 
				name: 'vehiculoview', 
				component: () => import('./pages/vehiculo/view.vue'), 
				props: true
			},
			{ 
				path: '/vehiculo/mis_vehiculos', 
				name: 'misvehiculos', 
				component: () => import('./pages/vehiculo/mis_vehiculos.vue'), 
				props: true
			},
			{ 
				path: '/vehiculo/add', 
				name: 'vehiculoadd', 
				component: () => import('./pages/vehiculo/add.vue'), 
				props: true
			},
	
			{ 
				path: '/vehiculo/edit/:id', 
				name: 'vehiculoedit', 
				component: () => import('./pages/vehiculo/edit.vue'), 
				props: true
			},
		

			{ 
				path: '/configuracion', 
				name: 'configuracion', 
				component: () => import('././pages/custom/configuracion.vue'), 
				props: true
			},
	
	
	
//Password reset routes
			{ 
				path: '/index/forgotpassword', 
				name: 'forgotpassword', 
				component: () => import('./pages/index/forgotpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword', 
				name: 'resetpassword', 
				component: () => import('./pages/index/resetpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword_completed', 
				name: 'resetpassword_completed', 
				component: () => import('./pages/index/resetpassword_completed.vue'), 
				props: true
			},
	
	
	
	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		const roleName = auth.userRole.toLowerCase();
		let rolePage;
		switch(roleName){
			case "user":
				rolePage = "user";
				break;
			default:
				rolePage = "home";
		}
		
		//Dashboard route
		//Display page based on user role
		routes.push({
			path: '/',
			alias: '/home', 
			name: 'home', 
			component: () => import(`./pages/home/${rolePage}.vue`),
			props: true
		});
	}
	else{
		routes.push({
			path: '/', 
			alias: '/index', 
			name: 'index', 
			component: () => import('./pages/index/index.vue'),
			props: true
		});
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		if (authRequired) {
			if(!user){
				return next({ path: '/',  query: { nexturl: to.fullPath } });
			}
			//authorize user
			if (!auth.canView(path)) {
				return next({path: "/error/forbidden"});
			}
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

 		//close dialog from previous page
		//store.closeDialogs('app/closeDialogs');
		
		next();
	});

	return router;
}
