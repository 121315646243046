
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": "Home",
    "icon": "pi pi-home text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/vehiculo",
    "label": "Vehiculos",
    "icon": "pi pi-car text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/turno",
    "label": "Turnos",
    "icon": "pi pi-calendar text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/usuarios",
    "label": "Usuarios",
    "icon": "pi pi-user text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/configuracion",
    "label": "Configuración",
    "icon": "pi pi-cog text-primary",
    "iconcolor": "",
    "target": "",
    
  }
],
	realizado: [    
{value: "SI", label: "Si"},
	{value: "NO", label: "No"}
    ],
	estado: [    
{value: "ACTIVO", label: "ACTIVO"},
	{value: "BAJA", label: "BAJA"}
    ],
	estadoTurno: [    
{value: "PENDIENTE", label: "PENDIENTE"},
	{value: "CONFIRMADO", label: "CONFIRMADO"},
	{value: "CANCELADO", label: "CANCELADO"}
    ],tipo_turno: [    
      {value: "SERVICE", label: "SERVICE"},
        {value: "MANTENIMIENTO", label: "MANTENIMIENTO"},
      
          ],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}